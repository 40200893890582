<template>
  <v-container
    id="login"
    class="fill-height"
    tag="section"
  >
    <v-row justify="center">
      <v-slide-y-transition appear>
        <base-material-card
          color="primary"
          light
          max-width="90%"
          min-width="40%"
        >
          <template #heading>
            <div class="text-center">
              <h1 class="display-2 font-weight-bold">
                {{ $tc('login', 1) }}
              </h1>
            </div>
          </template>

          <v-form @submit.prevent="login()">
            <v-card-text class="text-center">
              <v-text-field
                v-model="email"
                color="black"
                :label="$tc('email', 1)"
                prepend-icon="mdi-email"
                type="email"
                autocomplete="username"
              />

              <v-text-field
                v-model="password"
                class="mb-4"
                color="black"
                :label="$tc('password', 1)"
                prepend-icon="mdi-lock-outline"
                type="password"
                autocomplete="current-password"
              />

              <div
                v-if="$store.state.message === 'LoginFailed'"
                class="body-1 mb-4 red--text"
              >
                {{ $tc('invalids.loginfailed', 1) }}
              </div>

              <div>
                <pages-btn
                  color="black"
                  type="submit"
                >
                  {{ $tc('start', 1) }}
                </pages-btn>
              </div>
            </v-card-text>
          </v-form>
        </base-material-card>
      </v-slide-y-transition>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'PagesLogin',

    components: {
      PagesBtn: () => import('@/views/pages/components/Btn'),
    },

    data: () => ({
      email: '',
      password: '',
    }),

    methods: {
      login: function () {
        var self = this
        self.$auth.login({
          data: {
            email: self.email,
            password: self.password,
          },
        })
      },
    },
  }
</script>
